/**
 * Action types for redux
 */
export const LOGIN = 'LOGIN'
export const LOGOUT = 'LOGOUT'
export const SIGNUP = 'SIGNUP'
export const RESET = 'RESET'
export const CENTER = 'CENTER'
export const REGIONS = 'REGIONS'
export const FETCHING_EVENTS = 'FETCHING_EVENTS'
export const EVENTS = 'EVENTS'
export const EVENTDETAILS = 'EVENTDETAILS'
export const NEXTEVENTDATE = 'NEXTEVENTDATE'
export const UPCOMING_BOOKING_DETAILS = 'UPCOMING_BOOKING_DETAILS'
export const PAST_BOOKING_DETAILS = 'PAST_BOOKING_DETAILS'
export const CUSTOMERPROFILEDETAILS = 'CUSTOMERPROFILEDETAILS'
export const PAYMENTDETAILS = 'PAYMENTDETAILS'
export const INSTALLMENTS = 'INSTALLMENTS'
export const BOOK = 'BOOK'
export const SERVICES = 'SERVICES'
export const CLEARSTATUS = 'CLEARSTATUS'
export const TRAINERS = 'TRAINERS'
export const ROOMS = 'ROOMS'
export const CLASSES = 'CLASSES'
export const SEARCHCENTER = 'SEARCHCENTER'
export const PLANS = 'PLANS'
export const PLANDETAILS = 'PLANDETAILS'
export const BOOKPURCHASE = 'BOOKPURCHASE'
export const RESETBOOKPURCHASE = 'RESETBOOKPURCHASE'
export const MEMBERSHIP = 'MEMBERSHIP'
export const PAYMENTMODES = 'PAYMENTMODES'
export const RELATEDCONTACTSLIST = 'RELATEDCONTACTSLIST'
export const EMERGENCYCONTACTSLIST = 'EMERGENCYCONTACTSLIST'
export const VERIFY = 'VERIFY'
export const CONFIRM = 'CONFIRM'
export const GENERATE = 'GENERATE'
export const GET_PROFILE_IMAGE = 'GET_PROFILE_IMAGE'
export const PROFILE_IMAGE_ERROR = 'PROFILE_IMAGE_ERROR'
export const ADD_SHARED_MEMBERS = 'ADD_SHARED_MEMBERS'
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE'
export const RENDER_SERVICE_PAGE = 'RENDER_SERVICE_PAGE'
export const SELECTED_NAVBAR_INDEX = 'SELECTED_NAVBAR_INDEX'
export const QUICK_PAY_APPOINTMENTS = 'QUICK_PAY_APPOINTMENTS'
export const SALE_ID = 'SALE_ID'

/** 
 CARD ACTION CREATORS
**/
export const ADYENCONFIG = 'ADYENCONFIG'
export const ADYENCONFIG_ERROR = 'ADYENCONFIG_ERROR'
export const SAVEDCARD = 'SAVEDCARD'
export const SAVEDCARDS = 'SAVEDCARDS'
export const SAVED_PAYMENT_METHODS = 'SAVED_PAYMENT_METHODS'
export const ADYEN = 'adyen'
export const SAVE_ADYEN_SESSION = 'SAVE_ADYEN_SESSION'
export const STRIPE_SESSION_PLAN = 'STRIPE_SESSION_PLAN'
export const STRIPE_SESSION_CART = 'STRIPE_SESSION_CART'
export const THREE_D_SECURE_PLAN = 'THREE_D_SECURE_PLAN'
export const THREE_D_SECURE_CART = 'THREE_D_SECURE_CART'

/**
 * Appointments
 */
export const APPOINTMENTS = 'APPOINTMENTS'
export const APPOINTMENTSTATE = 'APPOINTMENTSTATE'
export const APPOINTMENT_TRAINERS = 'APPOINTMENT_TRAINERS'
export const APPOINTMENT_TYPES = 'APPOINTMENT_TYPES'
export const APPOINTMENTSPRICING = 'APPOINTMENTSPRICING'
export const FETCH_APPOINTMENTS = 'FETCH_APPOINTMENTS'
export const DONE_FETCHING_APPOINTMENTS = 'DONE_FETCHING_APPOINTMENTS'
export const BOOKPT = 'BOOKPT'

export const DISCOUNT_DETAILS = 'DISCOUNT_DETAILS'
export const CLEARSAVEDCARD = 'CLEAR_SAVEDCARD'
export const BOOKPLAN = 'BOOKPLAN'
export const RESETBOOKPLAN = 'RESETBOOKPLAN'
export const JOIN_WAITLIST_COURSE = 'JOIN_WAITLIST_COURSE'
export const RESET_JOIN_WAITLIST_COURSE = 'RESET_JOIN_WAITLIST_COURSE'

export const SETFROMEVENT = 'SETFROMEVENT'
export const CLEARFROMEVENT = 'CLEARFROMEVENT'

export const SAVEDRELATEDCONTACT = 'SAVEDRELATEDCONTACT'
export const CLEARSAVEDRELATEDCONTACT = 'CLEARSAVEDRELATEDCONTACT'

export const SAVEDEMERGENCYCONTACT = 'SAVEDEMERGENCYCONTACT'
export const CLEARSAVEDEMERGENCYCONTACT = 'CLEARSAVEDEMERGENCYCONTACT'

/**
 * Screen Breakpoints
 */
export const BREAKPOINT = {
  small: 425,
  medium: 768,
  large: 960,
  xlarge: 1440
}

/**
 * status codes for the booking
 */
export const STATUS_BOOKED = 1
export const STATUS_WAITLIST = 2
export const STATUS_CANCELED = 3
export const STATUS_CANCELED_BY_CONTACT = 4
export const STATUS_CANCELED_WAITLIST = 5
export const SETURLPREFIX = 'SETURLPREFIX'

/**
 * vod constants
 */
export const FETCHING_VIDEOS = 'FETCHING_VIDEOS'
export const VOD_CLEAR_DATA = 'VOD_CLEAR_DATA'
export const VOD_VIDEO = 'VOD_VIDEO'
export const VOD_VIDEOS = 'VOD_VIDEOS'
export const VOD_VIDEO_ERROR = 'VOD_VIDEO_ERROR'
export const VOD_VIDEOS_ERROR = 'VOD_VIDEOS_ERROR'
export const VOD_PLAYLISTS = 'VOD_PLAYLISTS'
export const VOD_PLAYLISTS_ERROR = 'VOD_PLAYLISTS_ERROR'
export const VOD_PLAYLIST_DETAILS = 'VOD_PLAYLIST_DETAILS'
export const VOD_PLAYLIST_DETAILS_ERROR = 'VOD_PLAYLIST_DETAILS_ERROR'
export const VOD_CATEGORIES = 'VOD_CATEGORIES'

// ** Rented videos and playlists
export const VOD_RENTED_VIDEOS = 'VOD_RENTED_VIDEOS'
export const VOD_RENTED_PLAYLISTS = 'VOD_RENTED_PLAYLISTS'
export const FETCHING_RENTED_VIDEOS = 'FETCHING_RENTED_VIDEOS'

/* course constants */
export const COURSES = 'COURSES'
export const FETCHING_COURSES = 'FETCHING_COURSES'
export const BOOKCOURSES = 'BOOKCOURSES'
export const COURSEDETAILS = 'COURSEDETAILS'
export const RESETBOOKCOURSES = 'RESETBOOKCOURSES'

/* GC constants */
export const GIFT = 'GIFT'
export const GDETAILS = 'GDETAILS'
export const GIFT_DETAILS = 'GIFT_DETAILS'
export const ALL_GIFT_CARDS = 'ALL_GIFT_CARDS'
export const CLEARGIFT = 'CLEARGIFT'

/* Waiver constants */
export const FETCHING_WAIVER = 'FETCHING_WAIVER'
export const WAIVER_DETAILS = 'WAIVER_DETAILS'
export const WAIVER_ERROR = 'WAIVER_ERROR'
export const SIGN_WAIVER = 'SIGN_WAIVER'
export const GET_IMAGE = 'GET_IMAGE'

/* Taxes constants */
export const TAXRATES = 'TAXRATES'

/* misc constants */
export const CONTACT_CHECK = 'CONTACT_CHECK'

/* Custom form */
export const FETCHING_CUSTOM_FORM = 'FETCHING_CUSTOM_FORM'
export const GET_FIELDS = 'GET_FIELDS'
export const GET_FIELDS_ERROR = 'GET_FIELDS_ERROR'
export const SUBMIT_FORM = 'SUBMIT_FORM'
export const SUBMIT_FORM_ERROR = 'SUBMIT_FORM_ERROR'

/* Product and Cart */

export const FETCHING_PRODUCTS = 'FETCHING_PRODUCTS'
export const FETCHING_PRODUCT_DETAIL = 'FETCHING_PRODUCT_DETAIL'
export const GET_PRODUCT_DETAIL = 'GET_PRODUCT_DETAIL'
export const GET_PRODUCT_ERROR = 'GET_PRODUCT_ERROR'
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const ADD_PRODUCT_ERROR = 'ADD_PRODUCT_ERROR'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const REMOVE_PRODUCT_ERROR = 'REMOVE_PRODUCT_ERROR'
export const STOP_FETCHING_PRODUCT_DETAIL = 'STOP_FETCHING_PRODUCT_DETAIL'
export const CART_CHECKOUT = 'CART_CHECKOUT'
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'
export const GET_BRANDS = 'GET_BRANDS'
export const GET_BRANDS_ERROR = 'GET_BRANDS_ERROR'
export const CLEAR_CHECKOUT_STATUS = 'CLEAR_CHECKOUT_STATUS'

export const PURCHASE_AND_BOOK_ERROR =
  'The payment was successful but the booking did not go through! Please retry!'

export const PAYMENT_SESSION_TIMEOUT = 15 * 60 * 1000 //15 minutes
export const SESSION_TIMEOUT_MESSAGE = 'Your session expired. Please try again!'

export const MENU_ITEMS = {
  myAccount: 'my_account',
  login: 'login',
  appointments: 'appointments',
  pricing: 'pricing',
  courses: 'courses',
  classes: 'classes'
}

export const APPOINTMENT_SELECTION_TYPE = {
  manual: 'manual',
  automatic: 'automatic'
}

export const BOOKING_TYPES = {
  upcoming: 'upcoming',
  past: 'past'
}

export const APPOINTMENT_PAYMENT_MODES = {
  credits: 'credits',
  quickpay: 'quickpay'
}

export const PAYMENT_METHOD_TYPES = {
  card: 'card',
  sepa_debit: 'sepa_debit',
  us_bank_account: 'us_bank_account'
}

export const ALERT_STATUS = {
  success: 'success',
  error: 'error'
}

export const PAYMENT_PLAN_OPTIONS = {
  oneTime: 'oneTime',
  paymentPlan: 'paymentPlan'
}
