import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PlaceholderLogo from '../Icons/PlaceholderLogo'

const FooterOuterContainer = styled.div`
  margin-top: 56px;
  width: 100%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
`

const FooterContainer = styled.div`
  width: 100%;
  padding: 48px 0 48px 0;
  max-width: 1200px;
  display: flex;
  justify-content: flex-end;
  & svg {
    opacity: 70%;
    &:hover {
      opacity: 100%;
    }
  }
`

export default function Footer({ theme }) {
  const [urlPrefix, setUrlPrefix] = useState('')

  useEffect(() => {
    setUrlPrefix(localStorage.getItem('url_prefix') || '')
  }, [])

  return (
    <FooterOuterContainer id="footer-container">
      <FooterContainer>
        <a
          href={`https://www.bookee.ai/demo/get-demo?utm_source=Iframe&utm_medium=${urlPrefix}&utm_campaign=Clientreferral`}
          target="_blank"
          rel="noreferrer"
        >
          <PlaceholderLogo
            fillColor={theme?.color?.primaryText || 'black'}
          ></PlaceholderLogo>
        </a>
      </FooterContainer>
    </FooterOuterContainer>
  )
}
